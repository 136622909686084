<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <div class="row">
        
        <div class="credits ml-auto">
          <div class="copyright">
            <i class="fa fa-copyright" style="font-weight: 200;"></i> 2022, Made with <i class="fa fa-heart heart"></i> by
            <a
              href="#"
              rel="noopener"
              >Biro SDM | IT Team</a> 
            for a better web.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'main-footer',
    data() {
      return {
        year: new Date().getFullYear()
      };
    }
  }
</script>
<style>
</style>
