<template>
  <span v-if="data.lenght == 0">
    <small class="error" v-text="message"/>
  </span>
</template>

<script>
  export default {
    name: "validation-error",
    props: {
      errors: {
        type: Array,
        default: () => ([])
      }, 
      data: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        message : "Kosong"
      }
    },
  }
</script>

<style lang="scss">
.error {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
