<template>
  <div class="login-page">
    
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="header-body text-center" style="margin-bottom: 15px;">
              <div class="row justify-content-center">
                <div class="text-center">
                  <h1 class="text-white" style="font-size: 1.2rem;">
                   Seleksi JPT Badan Kepegawaian Negara
                  </h1>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <b-overlay :show="isLoad" rounded="sm">
              <form @submit.prevent="login">
                <card type="login">
                  <h3 slot="header" class="header text-center">Login</h3>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend is-text>
                      <b-icon icon="person-fill"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input
                      type="text"
                      v-model.trim="$v.form.email.$model"
                      :state="!$v.form.email.$error"
                      placeholder="Username (NIP)"
                    />
                    <b-form-invalid-feedback>Username (NIP) Tidak Boleh Kosong</b-form-invalid-feedback>
                  </b-input-group>

                  <b-input-group class="mb-2">
                    <b-input-group-prepend is-text>
                      <b-icon icon="key-fill"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input
                      type="password"
                      v-model.trim="$v.form.password.$model"
                      :state="!$v.form.password.$error"
                      placeholder="Password"
                    />
                    
                    <b-form-invalid-feedback> 
                      Password Tidak Boleh Kosong
                    </b-form-invalid-feedback>
                    <b-input-group-prepend>
                </b-input-group-prepend>
                  </b-input-group>
                  <div slot="footer">
                    <p-button
                      native-type="submit"
                      slot="footer"
                      type="warning"
                      round
                      block
                      class="mb-3"
                      >Login</p-button
                    >
                    <p-button
                      native-type="button"
                      v-on:click ="kembaliDulu"
                      slot="footer"
                      type="danger"
                      round
                      block
                      class="mb-3"
                      >Kembali</p-button
                    >

                    <div class="pull-left">
                      <!-- <h6>
                        <a href="/password/reset" class="link footer-link"
                          >Forgot Password?</a
                        >
                      </h6> -->
                    </div>
                  </div>
                </card>
              </form>
              </b-overlay>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div
          class="full-page-background"
          style="background-image: url(/static/img/background/bg.jpg) "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { Card, Button } from "src/components/UIComponents";
import AppNavbar from "./Layout/AppNavbar";
import AppFooter from "./Layout/AppFooter";
import ValidationError from "src/components/UIComponents/ValidationError.vue";
import { mapGetters } from "vuex";
const { required } = require("vuelidate/lib/validators");

export default {
  components: {
    Card,
    AppNavbar,
    AppFooter,
    [Button.name]: Button,
    ValidationError,
  },
  data() {
    return {
      isLoad: false,
      form: {
        email: "",
        password: "",
      },
      kosong: "Tidak Boleh Kosong",
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: { required },
      password: { required },
    },
  },
  computed: {
    ...mapGetters(["currentUser", "processing", "loginError"]),
  },
  methods: {
    kembaliDulu(){
      this.$router.push({ name: 'Home' });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    login() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        const user = {
          email: this.form.email,
          password: this.form.password,
        };

        try {
          this.isLoad = true;
          this.$store
            .dispatch("login", { user })
            .then((response) => {
              if (this.loginError != null) {
                this.$notify({
                  icon: "nc-icon nc-bell-55",
                  message: this.loginError,
                  type: "danger",
                });
                this.form.email = ""
                this.form.password = ""
              } else{
                
                if (this.currentUser.roles == process.env.VUE_APP_ADMIN) {
                  this.$router.push({ name: "PesertaAdmin" });
                } else  if (this.currentUser.roles == process.env.VUE_APP_PESERTA) {
                  this.$router.push({ name: "UploadDokumen" });
                }
              }
              this.isLoad = false;
            })
            .catch((error) => {
              this.isLoad = false;
              // console.log(this.loginError)
            });
        } catch (e) {
          this.$notify({
            icon: "nc-icon nc-bell-55",
            message: "Invalid credentials!",
            type: "danger",
          });
          this.setApiValidation(e.response.data.errors);
        }
      } else {
        this.$notify({
          icon: "nc-icon nc-bell-55",
          message: "Ada data Kosong",
          type: "danger",
        });
      }
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style></style>
